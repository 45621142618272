<template>
  <div id="tmap" style="font-size: 14px" @click="pageClick">
    <div class="goBack" style="display:flex">
      <div class="house" @click="goBack">
        <img
          src="../assets/home.png"
          alt=""
          srcset=""
          style="width: 20px; height: 20px"
        />
      </div>

      <el-input
        class="serach"
        ref="serachInput"
        placeholder="请输入地址"
        prefix-icon="el-icon-search"
        v-model="keyWord"
        @click.stop.native
        @input="serachName"
        clearable
        @clear="
          () => {
            likeList = [];
          }
        "
        @keyup.enter.native="getDetailAdress(likeList[0])"
        @focus="showHistory = true"
      >
      </el-input>
    </div>
    <div class="serach-his" v-if="likeList.length">
      <div
        class="his-item"
        v-for="(item, index) in likeList"
        :key="index"
        @click.stop="getDetailAdress(item)"
        v-html="brightenKeyword(item.name, keyWord)"
      >
        {{ item.name }}
        <span class="his-city">{{ item.city }}</span>
      </div>
    </div>

    <!-- --------------------------左侧------------------------>
    <div class="left" v-if="!showFence">
      <!-- <div class="left1 flex-center f16 r-icon" @click.stop="showTip = true">
        <img :src="que" alt="" />
      </div> -->
      <div
        class="left1 flex-center r-icon"
        @click.stop="handleShowFence(true)"
        v-if="!showFence && !isDrawing"
      >
        <img :src="icon6" alt="" />
      </div>
      <div class="left2 flex-center r-icon" @click.stop="jumpToInfo">
        <img src="@/assets/imgs/3.png" style="width:40px; height:40px"  alt="" />
      </div>
    </div>

    <!-- --------------------------右侧---------------------------- -->
    <div class="i-com right" @click.stop="handleClear">
      <img :src="icon5" alt="" />
    </div>
    <img :src="add" alt="" class="i-center" />

    <!-- ------------------------- 全部围栏------------------ -->
    <div class="electronicFence" v-if="showFence && !isDrawing">
      <div class="closeFence" @click="closeFence()">
        <img :src="icon5" alt="" />
      </div>
      <div class="top">
        <div
          v-if="
            !allList.length ||
            (allList.length == 1 &&
              allList[allList.length - 1].points.length < 3)
          "
        >
          <van-empty description="暂无电子围栏" />
        </div>
        <div
          class="fenc"
          v-for="(item, index) in allList"
          :key="index"
          v-show="item.points.length"
        >
          <div class="title f14" @click="updateName(index)">
            <p>{{ item.name }}</p>
            <img :src="icon3" alt="" />
          </div>
          <div class="data">
            <div class="one">
              <img :src="info1" alt="" />
              <div>
                <p class="pone">
                  {{
                    (defaultParam * computeSignedArea(item.points)).toFixed(2)
                  }}
                </p>
                <p class="ptwo">总装机量(kWp)</p>
              </div>
            </div>
            <div class="one">
              <img :src="info3" alt="" />
              <div>
                <p class="pone">
                  {{
                    (
                      (defaultParam *
                        computeSignedArea(item.points) *
                        defaultWp) /
                      10
                    ).toFixed(2)
                  }}
                </p>
                <p class="ptwo">总投资(万元)</p>
              </div>
            </div>
          </div>
          <div class="data">
            <div class="one">
              <img :src="info2" alt="" />
              <div>
                <p class="pone">{{ computeSignedArea(item.points) }}</p>
                <p class="ptwo">面积(㎡)</p>
              </div>
            </div>
            <div class="one">
              <img :src="info4" alt="" />
              <div>
                <p class="pone">
                  {{
                    (
                      (defaultParam *
                        computeSignedArea(item.points) *
                        irradiationParam) /
                      10000
                    ).toFixed(2)
                  }}
                </p>
                <p class="ptwo">年发电量(万度)</p>
              </div>
            </div>
          </div>
          <div class="operate">
            <a @click.stop="removePoint(index)">删除绘制</a>
            <a @click.stop="getDetail(index)">获取详情</a>
            <a @click.stop="continueDraw(index)">继续绘制</a>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="projectDetail">
          <p>项目名称</p>
          <van-field
            v-model="mapName"
            @focus="mapName = ''"
            class="f16 fw700"
            placeholder="请输入项目名称"
            border=""
          />
        </div>
        <div class="projectDetail">
          <p>总面积(㎡)</p>
          <van-field
            v-model="grossArea"
            class="f16 fw700"
            placeholder="自动计算"
            border=""
            readonly
          />
        </div>
        <div class="projectDetail">
          <p>总装机量(kWp)</p>
          <van-field
            v-model="totalInstallCapacity"
            class="f16 fw700"
            placeholder="自动计算"
            border=""
            readonly
          />
        </div>
        <div class="projectDetail">
          <p>总投资(万元)</p>
          <van-field
            v-model="totalInvestment"
            class="f16 fw700"
            placeholder="自动计算"
            border=""
            readonly
          />
        </div>
        <div class="projectDetail">
          <p>年发电量(万度)</p>
          <van-field
            v-model="annualEnergyOutputs"
            class="f16 fw700"
            placeholder="自动计算"
            border=""
            readonly
          />
        </div>
        <div class="blueBtn">
          <button @click="handleSave">保 存</button>
        </div>
      </div>
    </div>

    <!-- ------------------------- 历史记录------------------ -->
    <div
      class="history"
      v-if="showHistory && !keyWord && recordList.length && !isDrawing"
    >
      <div class="historyTit">
        <p>历史记录</p>
        <img :src="delect" alt="" @click.stop="removeHis" />
      </div>
      <div class="record">
        <span
          class="recordName"
          v-for="(item, index) in recordList"
          :key="index"
          @click="chooseHistory(item)"
          >{{ item.name }}</span
        >
      </div>
    </div>

    <!-- --------------------------底部---------------------------- -->

    <!-- <div class="i-com i-ad">
      <img :src="icon2" alt="" />
    </div> -->

    <div class="i-com-c">
      <!-- 删除当前绘制 -->
      <div
        class="topB flex-center"
        @click.stop="handleClear(true)"
        v-show="isDrawing"
      >
        <img :src="icon5" alt="" />
      </div>

      <div class="flex-center">
        <!-- 撤销 -->
        <div
          class="leftB flex-center"
          @click.stop="handlePreStep"
          v-show="isDrawing"
        >
          <img :src="icon7" alt="" />
        </div>

        <!-- 绘制与手动打点 -->
        <div
          class="centerB flex-center"
          @click.stop="handlePoint"
          v-show="isDrawing"
        >
          <img :src="icon9" alt="" />
        </div>
        <div
          class="centerB flex-center"
          @click.stop="handleDraw"
          v-show="!isDrawing"
        >
          <img src="@/assets/imgs/1.png" style="width:50px;height:50px" alt="" v-if="!isDrawing" />
        </div>

        <!-- 确认 -->
        <div
          class="rightB flex-center"
          @click.stop="handleDraw"
          v-show="isDrawing"
        >
          <img :src="icon4" />
        </div>
      </div>
    </div>

    <div class="i-com i-gu" @click.stop="handleReset">
      <img src="@/assets/imgs/2.png" style="width:40px;height:40px"  alt="" />
    </div>

    <van-dialog v-model="showTip" title="提示" confirm-button-text="确 认">
      <van-cell title="删除地图上正在测绘所有的标记">
        <template #icon>
          <van-image width="14" height="14" :src="icon5" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="返回上一步">
        <template #icon>
          <van-image width="15" height="15" :src="icon7" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="点击对地图打点">
        <template #icon>
          <van-image width="15" height="17" :src="icon9" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="点击开始测绘">
        <template #icon>
          <van-image width="15" height="15" :src="icon3" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="完成测绘">
        <template #icon>
          <van-image width="15" height="15" :src="icon4" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="回到当前位置">
        <template #icon>
          <van-image width="14" height="15" :src="icon1" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="保存测绘内容">
        <template #icon>
          <van-image width="15" height="15" :src="icon6" class="tip-i" />
        </template>
      </van-cell>
      <van-cell title="我的历史项目">
        <template #icon>
          <van-image width="15" height="15" :src="myIcon" class="tip-i" />
        </template>
      </van-cell>
    </van-dialog>

    <van-dialog
      v-model="diaShow"
      title="标题"
      show-cancel-button
      @confirm="confirmName"
      confirm-button-text="确 认"
    >
      <van-field
        label="名称"
        v-model="currName"
        placeholder="请输入名称"
        @focus="currName = ''"
      />
    </van-dialog>

    <div id="bdmap"></div>
  </div>
</template>

<script>
import domtoimage from "dom-to-image";
import Tmap from "../com/map";
const que = require("../assets/imgs/que.png");
const dot = require("../assets/imgs/dot.png");
const dot1 = require("../assets/imgs/dot1.png");
const dot2 = require("../assets/imgs/dot2.png");
const add = require("../assets/imgs/add.png");
const myIcon = require("../assets/imgs/my.png");
const info1 = require("../assets/imgs/info1.png");
const info2 = require("../assets/imgs/info2.png");
const info3 = require("../assets/imgs/info3.png");
const info4 = require("../assets/imgs/info4.png");
const icon1 = require("../assets/imgs/icon1.png");
const icon2 = require("../assets/imgs/icon2.png");
const icon3 = require("../assets/imgs/icon3.png");
const icon4 = require("../assets/imgs/icon4.png");
const icon5 = require("../assets/imgs/icon5.png");
const icon6 = require("../assets/imgs/icon6.png");
const icon7 = require("../assets/imgs/icon7.png");
const icon8 = require("../assets/imgs/icon8.png");
const icon9 = require("../assets/imgs/icon9.png");
const delect = require("../assets/imgs/delect.png");
// const png1  = require("../assets/imgs/1.png");
// const png2  = require("../assets/imgs/2.png");
const PI = 3.1415926535897932384626;
const ee = 0.00669342162296594323;
const a = 6378245.0;
export default {
  data() {
    return {
      imgURLPreview: "", //截图
      defaultParam: 0, //默认屋顶参数
      defaultWp: 0, //默认每瓦成本
      irradiationParam: 0, //辐照参数
      mapName: "项目01",
      mapCode: "",
      annualEnergyOutputs: "", //年发电量
      diaShow: false,
      showTip: false,
      currName: "",
      currIndex: null,
      add,
      que,
      dot1,
      dot2,
      dot,
      icon1,
      icon2,
      icon3,
      icon4,
      icon5,
      icon6,
      icon7,
      info1,
      info2,
      info3,
      info4,
      icon8,
      icon9,
      delect,
      myIcon,
      infoList: [
        { img: info1, label: "总装机量(kWp)", num: 0 },
        { img: info2, label: "总投资(万元)", num: 0 },
        { img: info3, label: "面积(m2)", num: 0 },
        { img: info4, label: "年发电量(万度)", num: 0 },
      ],
      keyWord: "",
      lon: 117.24974,
      lat: 31.83065,
      localsearch: null,
      bdlocalsearch: null,
      geocode: null,
      markerTool: null,
      isDrawing: false, //是否是绘制中
      zoom: 18, //初始大小
      allList: [{ canEdit: true, points: [], name: "电子围栏-01" }],
      currItem: null, // 当前点集合
      dotIcon: new T.Icon({
        //点
        iconUrl: dot,
        iconSize: new T.Point(9, 9),
      }),
      originIcon: new T.Icon({
        //原点
        iconUrl: dot1,
        iconSize: new T.Point(21, 31),
        iconAnchor: new T.Point(12, 12),
      }),
      searchIcon: new T.Icon({
        //原点
        iconUrl: dot2,
        iconSize: new T.Point(21, 31),
        iconAnchor: new T.Point(12, 12),
      }),
      polygonStyle: {
        //面样式
        color: "#DB1111",
        weight: 3,
        opacity: 0.5,
        fillColor: "#DB1111",
        fillOpacity: 0.3,
      },
      area: 0,
      showFence: false,
      showHistory: false,
      likeList: [],
      recordList: [],
      searchMarker: null, // 搜索后的标志点
      tempPointIndex: ["", ""],
    };
  },

  created() {
    const mapCode = sessionStorage.getItem("mapCode") || "";
    this.lat = this.$info.lat;
    this.log = this.$info.log;
    if (mapCode) {
      this.mapCode = mapCode;
      this.getMeasureMap();
    }
    this.getHistorySearch();
  },

  mounted() {
    window.updateName = this.updateName;
    window.removePoint = this.removePoint;
    window.getDetail = this.getDetail;
    window.continueDraw = this.continueDraw;
    window.handleDraw = this.handleDraw;
    window.area = this.area;
    window.defaultParam = this.defaultParam;
    window.defaultWp = this.defaultWp;
    window.irradiationParam = this.irradiationParam;
    this.roadMap();
    this.roadBaiduMap();
  },

  computed: {
    grossArea() {
      let total = 0;
      let tTotalInstallCapacity = 0;
      let tTotalInvestment = 0;
      let tAnnualEnergyOutputs = 0;
      this.allList.forEach((item) => {
        let tempArea = this.computeSignedArea(item.points) - 0;
        total = total + tempArea;
        tTotalInstallCapacity =
          tTotalInstallCapacity +
          ((tempArea * this.defaultParam).toFixed(2) - 0);
        tTotalInvestment =
          tTotalInvestment +
          (((tempArea * this.defaultParam * this.defaultWp) / 10).toFixed(2) -
            0);
        tAnnualEnergyOutputs =
          tAnnualEnergyOutputs +
          ((
            (this.defaultParam * tempArea * this.irradiationParam) /
            10000
          ).toFixed(2) -
            0);
      });
      this.totalInstallCapacity = tTotalInstallCapacity.toFixed(2);
      this.totalInvestment = tTotalInvestment.toFixed(2);
      this.annualEnergyOutputs = tAnnualEnergyOutputs.toFixed(2);
      return total.toFixed(2);
    },
  },

  methods: {
    //搜索高亮
    brightenKeyword(val, keyWord) {
      if (keyWord.length > 0) {
        let keyWordArr = keyWord.split("");
        val = val + "";
        keyWordArr.forEach((item) => {
          if (val.indexOf(item) !== -1 && item !== "") {
            val = val.replace(
              new RegExp(item, "g"),
              `<font color="#f75353">${item}</font>`
              // '<font color="#f75353">' + item + "</font>"
            );
          }
        });
        return val;
      } else {
        return val;
      }
    },

    //暂存绘制的地图
    saveTempDraw() {
      let tempList = [];
      this.allList.forEach((item) => {
        if (item.points.length) {
          let temp = {
            measureName: item.name,
            coordinate: item.points,
          };
          tempList.push(temp);
        }
      });
      sessionStorage.setItem("detailList", JSON.stringify(tempList));
    },

    //获取暂存的绘制
    getLocalDraw() {
      let temp = sessionStorage.getItem("detailList")
        ? JSON.parse(sessionStorage.getItem("detailList"))
        : [];
      this.allList = temp.map((item) => {
        return {
          points: item.coordinate,
          name: item.measureName,
        };
      });
      if (this.allList.length) {
        this.currItem = this.allList[this.allList.length - 1];
      }
      this.handleDrawed();
    },

    async transformImage() {
      //转换成图片
      this.imgURLPreview = await new Promise((resolve, reject) => {
        domtoimage
          .toPng(document.getElementById("tmap"))
          .then((dataUrl) => {
            this.imgURLPreview = dataUrl;
            resolve(dataUrl);
          })
          .catch(function (error) {
            reject("err");
            console.error("oops, something went wrong!", error);
          });
      });
    },

    pageClick() {
      //页面点击
      this.$refs.serachInput.blur();
      this.showHistory = false;
    },

    jumpToInfo() {
      //去我的详情页
      this.$router.push({
        path: "/userInfo",
      });
      this.saveTempDraw();
    },

    handleShowFence(isShow) {
      // 获取电子围栏的城市编码信息
      this.map.closeInfoWindow();
      let points = this.allList[0].points;
      if (points.length > 2) {
        if (isShow) {
          this.showFence = true;
        }
        const temp = new this.T.LngLat(points[2].lng, points[2].lat);
        this.geocode.getLocation(temp, this.searchResult);
      } else {
        this.$dialog.alert({
          message: "请先绘制电子围栏",
        });
      }
    },

    searchResult(result) {
      const {
        addressComponent: { city_code },
      } = result;
      this.$http
        .post("/measureMap/queryMapConfig", {
          cityCode: city_code.substring(3, city_code.length),
        })
        .then((res) => {
          if (res.data.code == 200) {
            const { buildRatio, costPerWatt, rpupg } = res.data.data;
            this.defaultParam = buildRatio;
            this.defaultWp = costPerWatt;
            this.irradiationParam = rpupg;
          }
        });
    },

    getMeasureMap() {
      //获取项目下的电子围栏
      this.$http
        .post("/measureMap/queryOne", { mapCode: this.mapCode })
        .then((res) => {
          if (res.data.code == 200) {
            const { detailList } = res.data.data;
            this.allList = detailList.map((item) => {
              return {
                points: JSON.parse(item.coordinate),
                name: item.measureName,
              };
            });
            if (this.allList.length) {
              this.currItem = this.allList[this.allList.length - 1];
            }
            this.handleDrawed();
          }
        });
    },

    handleClear(isCurr) {
      //清除所有标注点
      this.map.clearOverLays();
      this.allList = [{ canEdit: true, points: [], name: "电子围栏-01" }];
      this.currItem = null;
      this.isDrawing = false;
      this.mapCode = "";
      this.map.removeEventListener("click", this.mapClick);
      this.getOrigin();
      if (!isCurr) {
        sessionStorage.clear();
      }
    },

    async handleSave() {
      //保存
      const { allList } = this;
      if (
        !allList.length ||
        (allList.length == 1 && allList[allList.length - 1].points < 3)
      ) {
        this.$dialog
          .alert({
            message: "请先绘制电子围栏",
          })
          .then(() => {});
        return;
      }
      this.showFence = false;
      await this.transformImage();
      console.log(this.imgURLPreview);
      let data = {
        memberCode: this.$info.memberCode || "202207289034832374411",
        memberName: "",
        memberLogo: "",
        detailList: [],
        mapName: this.mapName,
        mapImg: this.imgURLPreview,
        totalInstallCapacity: this.totalInstallCapacity,
        totalInvestment: this.totalInvestment,
        grossArea: this.grossArea,
        annualEnergyOutput: this.annualEnergyOutputs,
        mapCode: this.mapCode || "",
      };
      this.allList.forEach((item) => {
        if (item.points.length) {
          let temp = {
            measureName: item.name,
            coordinate: item.points,
          };
          data.detailList.push(temp);
        }
      });

      this.$http.post("/measureMap/save", data).then((res) => {
        if (res.data.code == 200) {
          // this.showFence = false
          this.$toast.success("添加成功");
          this.handleClear();
        } else {
          this.$toast.fail(res.data.message);
        }
      });
    },

    handlePreStep() {
      //撤销 返回上一步
      if (this.isDrawing) {
        this.currItem.points.splice(this.currItem.points.length - 1, 1);
        this.handleDrawed();
      }
    },

    handleDrawed() {
      //重新渲染
      this.map.clearOverLays();
      this.getOrigin();
      this.allList.forEach((e, eIndex) => {
        e.points.forEach((item) => {
          const marker = new T.Marker(new T.LngLat(item.lng, item.lat), {
            icon: this.dotIcon,
          });
          this.map.addOverLay(marker);
        });
        if (e.points.length == 2) {
          const line = new T.Polyline(e.points, { color: "#DB1111" });
          this.map.addOverLay(line);
          const label = new T.Label({
            text: `${this.getDistance(e.points[0], e.points[1])}m`,
            position: this.getCenterLonLat(e.points[0], e.points[1]),
            offset: new T.Point(-30, 0),
          });
          label.setFontColor("#DB1111");
          label.setFontSize(13);
          this.map.addOverLay(label);
        }
        if (e.points.length > 2) {
          const polygon = new T.Polygon(e.points, this.polygonStyle);
          let pointLength = e.points.length;
          e.points.forEach((item, i) => {
            let lastPoint =
              pointLength - 1 === i ? e.points[0] : e.points[i + 1];
            const label = new T.Label({
              text: `${this.getDistance(e.points[i], lastPoint)}m`,
              position: this.getCenterLonLat(e.points[i], lastPoint),
              offset: new T.Point(-30, 0),
            });
            label.setFontColor("#DB1111");
            label.setFontSize(13);
            this.area = this.computeSignedArea(e.points);
            this.map.addOverLay(label);
          });
          //注册面事件
          polygon.addEventListener("click", () => {
            this.polygonClick(e, e.points, eIndex);
          });
          this.tempPointIndex = [e.points, eIndex]; //临时存放
          //向地图上添加面
          this.map.addOverLay(polygon);
        }
      });
    },

    polygonClick(e, point, eIndex) {
       this.area = this.computeSignedArea(e.points);
      //电子围栏点击事件
      if (this.isDrawing) {
        return;
      }
      let lonlat = this.getCenterPoint(point);

      var infoWin1 = new T.InfoWindow();
      var sContent = `
       <div style="display: flex;align-items:center" onClick="updateName(${eIndex})"><span style="font-size:14px">${this.allList[eIndex].name}</span> <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info5.png" alt="" style="width:12px;height:12px;margin-left:5px" ></div>
            <div style="display: flex; flex-wrap:wrap;width:300px;margin-top:8px ;justify-content: space-between;">
                 <div style='display: flex;height:35px;margin-bottom:10px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info1.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;">获取中</span>
                    <span>总装机量(kWp)</span>
                  </div>
                </div>
                 <div style='display: flex;height:35px;    margin-right: 13px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info2.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;">获取中</span>
                    <span>总投资(万元)</span>
                  </div>
                </div>
                 <div style='display: flex;height:35px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info3.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;">${this.area}</span>
                    <span>面积(m2)</span>
                  </div>
                </div>
                 <div style='display: flex;height:35px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info4.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;" ref="name">获取中</span>
                    <span>年发电量(万度)</span>
                  </div>
                </div>
            </div>
             <div style="margin-top: 15px !important;width:230px;display:flex;justify-content: space-between;margin:10px auto 0">
                   <a style="font-weight: 700;font-size: 16px;" onClick="removePoint(${eIndex})">删除绘制</a>
                   <a style="font-weight: 700;font-size: 16px;" onClick="getDetail(${eIndex})">获取详情</a>
                   <a style="font-weight: 700;font-size: 16px;" onClick="handleDraw()">绘制下个</a>
                </div> 
          `;
      infoWin1.setContent(sContent);
      this.map.openInfoWindow(infoWin1, lonlat);
      const lngLat = new T.LngLat(point[0].lng, point[0].lat);
      this.geocode.getLocation(lngLat, (result) => {
        console.log(result);
        const {
          addressComponent: { city_code },
        } = result;
        this.$http
          .post("/measureMap/queryMapConfig", {
            cityCode: city_code.substring(3, city_code.length),
          })
          .then((res) => {
            if (res.data.code == 200) {
              const { buildRatio, costPerWatt, rpupg } = res.data.data;
              this.defaultParam = buildRatio;
              this.defaultWp = costPerWatt;
              this.irradiationParam = rpupg;
              var sContent = `
           <div style="display: flex;align-items:center" onClick="updateName(${eIndex})"><span style="font-size:14px">${
                this.allList[eIndex].name
              }</span> <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info5.png" alt="" style="width:12px;height:12px;margin-left:5px" ></div>
            <div style="display: flex; flex-wrap:wrap;width:300px;margin-top:8px ;justify-content: space-between;">
                 <div style='display: flex;height:35px;margin-bottom:10px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info1.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;">${(
                      this.defaultParam * this.area
                    ).toFixed(2)}</span>
                    <span>总装机量(kWp)</span>
                  </div>
                </div>
                 <div style='display: flex;height:35px;    margin-right: 13px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info2.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;">${(
                      (this.defaultParam * this.area * this.defaultWp) /
                      10
                    ).toFixed(2)}</span>
                    <span>总投资(万元)</span>
                  </div>
                </div>
                 <div style='display: flex;height:35px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info3.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;">${
                      this.area
                    }</span>
                    <span>面积(m2)</span>
                  </div>
                </div>
                 <div style='display: flex;height:35px'>
                  <img src="https://api.zhongliangxny.com/resources/upload/mapimg/info4.png" alt="" style="width:33px;height:33px;margin-right:5px">
                  <div style='display: flex; flex-direction: column;justify-content: space-between;'>
                    <span style="font-weight: 700;font-size: 16px;" ref="name">${(
                      (this.defaultParam * this.area * this.irradiationParam) /
                      10000
                    ).toFixed(2)}</span>
                    <span>年发电量(万度)</span>
                  </div>
                </div>
               
            </div>
             <div style="margin-top: 15px !important;width:230px;display:flex;justify-content: space-between;margin:10px auto 0">
                   <a style="font-weight: 700;font-size: 16px;" onClick="removePoint(${eIndex})">删除绘制</a>
                   <a style="font-weight: 700;font-size: 16px;" onClick="getDetail(${eIndex})">获取详情</a>
                   <a style="font-weight: 700;font-size: 16px;" onClick="handleDraw()">绘制下个</a>
                </div> `;
              infoWin1.setContent(sContent);
              this.map.openInfoWindow(infoWin1, lonlat);
            }
          });
      });
    },

    removePoint(eIndex) {
      if (eIndex < 0) {
        return;
      }
      //删除某个区域
      this.$dialog
        .confirm({
          title: "标题",
          message: "确认删除该绘制区域码",
        })
        .then(() => {
          this.allList.splice(eIndex, 1);
          this.handleDrawed();
        });
    },

    continueDraw(eIndex) {
      //继续绘制
      this.map.closeInfoWindow();
      this.currItem = this.allList[eIndex];
      this.handleDraw();
    },

    updateName(eIndex) {
      this.currName = this.allList[eIndex].name;
      this.currIndex = eIndex;
      this.diaShow = true;
    },

    confirmName() {
      //确定修改名称
      this.allList[this.currIndex].name = this.currName;
      this.map.closeInfoWindow();
      this.polygonClick(
        "",
        this.allList[this.currIndex].points,
        this.currIndex
      );
    },

    getDetail(e) {
      //获取详情
      const { lng, lat } = this.allList[e].points[0];
      const lngLat = new T.LngLat(lng, lat);
      this.geocode.getLocation(lngLat, (result) => {
        const {
          addressComponent: { city_code, city, province, province_code },
        } = result;
        this.$router.push({
          path: "/detail",
          query: {
            area: this.area,
            city: JSON.stringify({
              name: city,
              code: city_code.substring(3, city_code.length),
            }),
            province: JSON.stringify({
              name: province,
              code: province_code.substring(3, province_code.length),
            }),
          },
        });
      });
      this.saveTempDraw();
    },

    getOrigin() {
      //获取原点
      const marker = new T.Marker(
        new T.LngLat(this.$info.log, this.$info.lat),
        {
          icon: this.originIcon,
        }
      );
      this.map.addOverLay(marker); //初始位置打点
    },

    handlePoint() {
      
      //打点
      if (this.isDrawing) {
        this.mapClick(this.map.getCenter(), true);
      }
    },

    handleDraw() {
      // window.location.href='https://dnbh5.nengliangkeji.com/#/newDownload';
  
      // return
      //绘制按钮
      let isLast = false;
      if (!this.isDrawing) {
        //绘制中
        if (!this.currItem) {
          if (!this.allList.length) {
            this.allList.push({
              canEdit: true,
              points: [],
              name: "电子围栏-01",
            });
          }
          this.currItem = this.allList[0];
        }
        this.map.addEventListener("click", this.mapClick);
      } else {
        // 结束
        if (
          this.currItem.points.length < 3 &&
          this.currItem.points.length > 0
        ) {
          this.$dialog.alert({
            title: "提示",
            message: "请至少描绘3个点",
          });
          return;
        }
        if (this.currItem.points.length > 2) {
          this.allList.push({
            canEdit: true,
            points: [],
            name: "电子围栏-0" + (this.allList.length + 1),
          });
          this.currItem = this.allList[this.allList.length - 1];
          isLast = true;
        }
        this.map.removeEventListener("click", this.mapClick);
      }
      this.isDrawing = !this.isDrawing;
      if (isLast) {
        this.polygonClick("", this.tempPointIndex[0], this.tempPointIndex[1]);
      }
      this.map.closeInfoWindow();
    },

    mapClick(e, isCenter) {
      //地图点击事件
      if (!e?.lnglat?.getLng() && !e.lat) {
        return;
      }
      const lng = isCenter ? e.lng : e.lnglat.getLng();
      const lat = isCenter ? e.lat : e.lnglat.getLat();
      //创建标注对象
      const marker = new T.Marker(new T.LngLat(lng, lat), {
        icon: this.dotIcon,
      });
      //向地图上添加标注
      this.map.addOverLay(marker);
      this.currItem.points.push(new T.LngLat(lng, lat));
      this.handleDrawed();
    },

    handleReset() {
      //回到初始定位
      this.map.centerAndZoom(
        new T.LngLat(this.$info.log, this.$info.lat),
        this.zoom
      );
    },

    serachName() {
      //查询
      console.log(this.keyWord);
      if (this.keyWord) {
        this.bdlocalsearch.search(this.keyWord);
      } else {
        this.likeList = [];
      }
    },

    roadBaiduMap() {
      //百度地图
      this.baiduMap = new BMap.Map("bdmap");
      this.bdlocalsearch = new BMap.LocalSearch(this.baiduMap, {
        renderOptions: {
          map: this.baiduMap,
        },
        onSearchComplete: (res) => {
          console.log("---------------------");
          console.log(res);
          console.log("---------------------");
          if (res.as) {
            this.likeList = res.as.map((item) => {
              return {
                name: item.title,
                lonlat: item.point,
                city: item.city,
              };
            });
          }
        },
      });
    },

    roadMap() {
      //初始化地图
      const { lon, lat } = this;
      Tmap.init()
        .then((T) => {
          this.T = T;
          const imageURL =
            "https://t0.tianditu.gov.cn/img_w/wmts?" +
            "SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
            "&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=" +
            this.$key;
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          const config = { layers: [lay] };
          this.map = new T.Map("tmap", config);
          this.map.centerAndZoom(
            new T.LngLat(this.$info.log, this.$info.lat),
            this.zoom
          );
          this.getOrigin();
          this.geocode = new T.Geocoder(); //反地址解析
          document.getElementsByClassName(
            "tdt-control-copyright tdt-control"
          )[0].style.display = "none"; //去水印
          if (!this.markerTool) {
            this.getLocalDraw();
          }
        })
        .catch();
    },

    //计算两个经纬度之间距离
    getDistance(point1, point2) {
      if (point1.lat == point2.lat && point1.lng == point2.lng) {
        return 0;
      }
      var f = this.getRad((point1.lat + point2.lat) / 2);
      var g = this.getRad((point1.lat - point2.lat) / 2);
      var l = this.getRad((point1.lng - point2.lng) / 2);
      var sg = Math.sin(g);
      var sl = Math.sin(l);
      var sf = Math.sin(f);
      var s, c, w, r, d, h1, h2;
      var a = 6378137.0; //The Radius of eath in meter.
      var fl = 1 / 298.257;
      sg = sg * sg;
      sl = sl * sl;
      sf = sf * sf;
      s = sg * (1 - sl) + (1 - sf) * sl;
      c = (1 - sg) * (1 - sl) + sf * sl;
      w = Math.atan(Math.sqrt(s / c));
      r = Math.sqrt(s * c) / w;
      d = 2 * w * a;
      h1 = (3 * r - 1) / 2 / c;
      h2 = (3 * r + 1) / 2 / s;
      s = d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg));
      //  s = s/1000;
      s = s.toFixed(0); //指定小数点后的位数。
      return s;
    },

    getRad(d) {
      var PI = Math.PI;
      return (d * PI) / 180.0;
    },

    //计算两个经纬度的中心点
    getCenterLonLat(point1, point2) {
      const { lng: oneLon, lat: oneLat } = point1;
      const { lng: twoLon, lat: twoLat } = point2;
      //oneLon：第一个点的经度；oneLat：第一个点的纬度；twoLon：第二个点的经度；twoLat：第二个点的纬度；
      let aLon = 0,
        aLat = 0;
      let bLon = Number(oneLon) - Number(twoLon);
      let bLat = Number(oneLat) - Number(twoLat);
      //Math.abs()绝对值
      if (bLon > 0) {
        aLon = Number(oneLon) - Math.abs(bLon) / 2;
      } else {
        aLon = Number(twoLon) - Math.abs(bLon) / 2;
      }
      if (bLat > 0) {
        aLat = Number(oneLat) - Math.abs(bLat) / 2;
      } else {
        aLat = Number(twoLat) - Math.abs(bLat) / 2;
      }
      var c = {
        lng: aLon,
        lat: aLat,
      };
      return c;
    },

    //取多边形中心点坐标
    getCenterPoint(data) {
      var lng = 0.0,
        lat = 0.0;
      let latList = [];
      for (var i = 0; i < data.length; i++) {
        if (!data[i]) {
          continue;
        }
        lng = lng + parseFloat(data[i]["lng"]);
        lat = lat + parseFloat(data[i]["lat"]);
        latList.push(parseFloat(data[i]["lat"]));
      }
      lng = lng / data.length;
      // lat = lat / data.length;
      lat = Math.max(...latList);
      //
      return { lng, lat };
    },

    //计算面积
    computeSignedArea(path) {
      let radius = 6378137;
      let len = path.length;
      if (len < 3) return 0;
      let total = 0;
      let prev = path[len - 1];
      let prevTanLat = Math.tan((Math.PI / 2 - (prev.lat / 180) * Math.PI) / 2);
      let prevLng = (prev.lng / 180) * Math.PI;
      for (let i in path) {
        let tanLat = Math.tan(
          (Math.PI / 2 - (path[i].lat / 180) * Math.PI) / 2
        );
        let lng = (path[i].lng / 180) * Math.PI;
        total += this.polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
        prevTanLat = tanLat;
        prevLng = lng;
      }
      return Math.abs(total * (radius * radius)).toFixed(2);
    },

    polarTriangleArea(tan1, lng1, tan2, lng2) {
      let deltaLng = lng1 - lng2;
      let t = tan1 * tan2;
      return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
    },

    // 点击关闭所有电子围栏
    closeFence() {
      this.showFence = false;
    },
    // 显示历史记录
    serachFocus() {
      this.showHistory = true;
    },

    getDetailAdress(item, isHistory) {
      //点击详情
      if (!item) {
        return;
      }
      this.keyWord = item.name;
      this.likeList = [];
      const { lng, lat } = item.lonlat;
      const LngLat = isHistory ? [lng, lat] : this.bd09towgs84(lng, lat);
      //坐标转换
      if (this.marker) {
        this.map.removeOverLay(this.marker);
      }
      this.recordList.unshift({
        name: item.name,
        lonlat: {
          lng: LngLat[0],
          lat: LngLat[1],
        },
      });
      this.marker = new T.Marker(new T.LngLat(LngLat[0], LngLat[1]), {
        icon: this.searchIcon,
      });
      this.map.addOverLay(this.marker); //初始位置打点
      this.map.centerAndZoom(new T.LngLat(LngLat[0], LngLat[1]), this.zoom);
      if (!isHistory) {
        this.saveHistorySearch(LngLat);
      }
    },

    removeHis() {
      //删除所有历史记录
      this.$http.post("/measureSearchHistory/deleteMemberHistory", {
        memberCode: this.$info.memberCode,
      });
      this.recordList = [];
    },

    saveHistorySearch(LngLat) {
      // 保存历史记录
      this.$http.post("/measureSearchHistory/saveHistory", {
        memberCode: this.$info.memberCode,
        searchName: this.keyWord,
        coordinate: { lng: LngLat[0], lat: LngLat[1] },
      });
    },

    getHistorySearch() {
      //查询搜索记录
      this.$http
        .post("/measureSearchHistory/queryList", {
          memberCode: this.$info.memberCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log("历史记录", res);
            let temp = res.data.data.map((item) => {
              return {
                name: item.searchName,
                lonlat: item.coordinate ? JSON.parse(item.coordinate) : "",
              };
            });
            this.recordList = temp;
          }
        });
    },

    // 关闭历史记录 获取输入内容
    closeFocus(e) {
      if (e) {
        this.recordList.unshift(e);
      }
      this.showHistory = false;
    },

    // 点击历史记录放入input
    chooseHistory(item) {
      this.getDetailAdress(item, true);
    },

    //百度坐标系转wgs84坐标系
    bd09towgs84(lng, lat) {
      // 百度坐标系先转为火星坐标系
      const gcj02 = this.bd09togcj02(lng, lat);
      // 火星坐标系转wgs84坐标系
      const result = this.gcj02towgs84(gcj02[0], gcj02[1]);
      return result;
    },

    // 百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02)的转换
    bd09togcj02(bd_lon, bd_lat) {
      var x_pi = (3.14159265358979324 * 3000.0) / 180.0;
      var x = bd_lon - 0.0065;
      var y = bd_lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
      var gg_lng = z * Math.cos(theta);
      var gg_lat = z * Math.sin(theta);
      return [gg_lng, gg_lat];
    },

    //GCJ02（火星坐标系） 转换为 WGS84
    gcj02towgs84(lng, lat) {
      var dlat = this.transformlat(lng - 105.0, lat - 35.0);
      var dlng = this.transformlng(lng - 105.0, lat - 35.0);
      var radlat = (lat / 180.0) * PI;
      var magic = Math.sin(radlat);
      magic = 1 - ee * magic * magic;
      var sqrtmagic = Math.sqrt(magic);
      dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
      dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
      const mglat = lat + dlat;
      const mglng = lng + dlng;
      return [lng * 2 - mglng, lat * 2 - mglat];
    },

    //经度转换
    transformlat(lng, lat) {
      var ret =
        -100.0 +
        2.0 * lng +
        3.0 * lat +
        0.2 * lat * lat +
        0.1 * lng * lat +
        0.2 * Math.sqrt(Math.abs(lng));
      ret +=
        ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
          2.0) /
        3.0;
      ret +=
        ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) *
          2.0) /
        3.0;
      ret +=
        ((160.0 * Math.sin((lat / 12.0) * PI) +
          320 * Math.sin((lat * PI) / 30.0)) *
          2.0) /
        3.0;
      return ret;
    },
    //纬度转换
    transformlng(lng, lat) {
      var ret =
        300.0 +
        lng +
        2.0 * lat +
        0.1 * lng * lng +
        0.1 * lng * lat +
        0.1 * Math.sqrt(Math.abs(lng));
      ret +=
        ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
          2.0) /
        3.0;
      ret +=
        ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) *
          2.0) /
        3.0;
      ret +=
        ((150.0 * Math.sin((lng / 12.0) * PI) +
          300.0 * Math.sin((lng / 30.0) * PI)) *
          2.0) /
        3.0;
      return ret;
    },

    goBack() {
      wx.miniProgram.switchTab({ url: "/pages/home/home" });
    },
  },
};
</script>



<style lang="less" scoped>
#tmap {
  width: 100%;
  height: 100vh;
  position: absolute;
}
.goBack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100%;
  height: 40px;
  // padding-top: 10px;
  // background-color: #077de1;
  .house {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 15px;
    margin-right: 20px;
  }
}

.fw700 {
  font-weight: 700;
}
.f16 {
  font-size: 16px;
}
.f14 {
  font-size: 164x;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right {
  right: 11px;
  bottom: 214px;
  img {
    width: 25px;
    height: 25px;
  }
}
.i-center {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
}

.i-com-c {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  border-radius: 10px;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  .topB {
    width: 44px;
    height: 60px;
    background: url(../assets/imgs/topB.png) no-repeat;
    background-size: cover;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .leftB {
    width: 60px;
    height: 44px;
    background: url(../assets/imgs/left.png) no-repeat;
    background-size: cover;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .centerB {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #fff;
    img {
      width: 33px;
      height: 33px;
    }
  }
  .rightB {
    width: 60px;
    height: 44px;
    background: url(../assets/imgs/right.png) no-repeat;
    background-size: cover;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
.i-com {
  position: fixed;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;
  border-radius: 10px;
}
.i-gu {
  bottom: 40px;
  right: 12px;
  img {
    width: 25px;
    height: 25px;
  }
}
.i-ad {
  bottom: 40px;
  left: 12px;
  img {
    width: 25px;
    height: 25px;
  }
}

.serach {
  position: fixed;
  top: 10px;
  left: 15%;
  // transform: translateX(-50%);
  z-index: 999;
  width: 300px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
}
.serach-his {
  position: fixed;
  top: 55px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  .his-item {
    font-size: 15px;
    font-weight: 700;
    box-sizing: border-box;
    height: 42px;
    line-height: 42px;
    width: 350px;
    background: #fff;
    padding-left: 5px;
    border-bottom: 1px solid #ccc;
    color: #666666;
    .his-city {
      margin-left: 5px;
      font-size: 13px;
      color: #999999;
    }
  }
}

.info-main {
  display: flex;
  flex-wrap: wrap;
  width: 340px;
  position: fixed;
  top: 150px;
  left: 20px;
  background: #fff;
  z-index: 999;
}
.info-con {
  display: flex;
  width: 120px;
  .info-dis {
    display: flex;
    flex-direction: column;
  }
}
.cred {
  color: red;
}
.left {
  position: absolute;
  bottom: 98px;
  right: 12px;
  z-index: 999;
  .r-icon {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 10px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .left1 {
    margin-top: 20px;
  }
  .left2 {
    margin-top: 20px;
  }
}
.electronicFence {
  position: absolute;
  top: 71px;
  left: 50%;
  transform: translateX(-50%);
  width: 351px;
  // height: 614px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 1000;
  .top {
    max-height: 346px;
    width: 100%;
    overflow-y: scroll;
    .fenc {
      height: 185px;
      padding: 12px;
      box-sizing: border-box;
      .title {
        display: flex;
        p {
          color: #333;
          margin-right: 6px;
        }
        img {
          width: 15px;
          height: 15px;
        }
      }
      .data {
        display: flex;
        margin-top: 5px;
        justify-content: space-around;
        .one {
          display: flex;
          align-items: center;
          width: 147px;
          img {
            width: 33px;
            height: 33px;
            margin-right: 8px;
          }
          .pone {
            font-size: 16px;
            font-weight: 700;
            color: #333;
          }
          .ptwo {
            font-size: 14px;
            color: #333;
          }
        }
      }
      .operate {
        display: flex;
        justify-content: space-between;
        padding: 0 60px;
        box-sizing: border-box;
        margin-top: 10px;
        a {
          font-weight: 700;
          font-size: 15px;
          color: #238bf9;
        }
      }
    }
  }
  .bottom {
    height: 268px;
    width: 100%;
    padding: 20px 12px;
    box-sizing: border-box;
    border-top: 1px solid #333;
    .projectDetail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      margin-bottom: 10px;
      .van-cell {
        width: 210px;
        height: 27px;
        padding: 0;
        color: #999;
        border: 1px solid #707070;
        border-radius: 5px;
      }
      /deep/.van-field__control {
        text-align: center !important;
      }
      p {
        color: #333;
      }
      // 修改placeholder默认样式
      ::-webkit-input-placeholder {
        font-size: 12px;
        text-align: center;
      }
      /* Firefox 4-18 */
      :-moz-placeholder {
        font-size: 12px;
        text-align: center;
      }
      /* Firefox 19-50 */
      ::-moz-placeholder {
        font-size: 12px;
        text-align: center;
      }
      /* - Internet Explorer 10–11
      - Internet Explorer Mobile 10-11 */
      :-ms-input-placeholder {
        font-size: 12px !important;
        text-align: center !important;
      }
      /* Edge (also supports ::-webkit-input-placeholder) */
      ::-ms-input-placeholder {
        font-size: 12px;
        text-align: center;
      }
      /* CSS Working Draft */
      ::placeholder {
        font-size: 12px;
        text-align: center;
      }
    }
    .blueBtn {
      margin-top: 22px;
      button {
        width: 176px;
        height: 34px;
        background-color: #238bf9;
        border-radius: 5px;
        color: #fff;
        margin-left: 84px;
      }
    }
  }
  .closeFence {
    position: absolute;
    top: 10px;
    right: 12px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
.history {
  position: absolute;
  top: 58px;
  left: 50%;
  transform: translateX(-50%);
  width: 351px;
  // height: 150px;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  .historyTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 18px;
      height: 17px;
    }
  }
  .record {
    // margin-top: 8px;
    // height: 102px;
    max-height: 380px;
    // overflow: hidden;
    padding-top: 10px;
    // padding-bottom: 10px;
    .recordName {
      max-width: 320px;
      height: 26px;
      line-height: 26px;
      padding: 0 12px;
      box-sizing: border-box;
      font-size: 12px;
      color: #666;
      background-color: #f2f2f2;
      box-sizing: border-box;
      margin-right: 8px;
      display: inline-block;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
/deep/.van-dialog__header {
  padding-top: 15px;
}
.tip-i {
  margin-right: 15px;
  margin-top: 5px;
}
/deep/.tdt-container a.tdt-infowindow-close-button {
  font-size: 25px !important;
}
/deep/.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  border-radius: 10px;
}
/deep/.tdt-label {
  line-height: 18px;
  padding: 0px;
}
/deep/.tdt-pane {
  // z-index: 999;
}
</style>