import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import './assets/css/global.css'
Vue.use(less)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import 'vant/lib/index.css';
import Vant from "vant"
Vue.use(Vant)

Vue.config.productionTip = false


import axios from "axios";
// axios.defaults.baseURL = "http://192.168.1.13:8777/api/";
axios.defaults.baseURL = "https://api.zhongliangxny.com/api";
Vue.prototype.$http = axios;
Vue.prototype.$info = {
  memberCode: '202207289034832374411',
  lat: 31.83065,
  log: 117.24974
}
Vue.prototype.$key = '080d407ec687f85fbc9e491ee08625d2'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
